<template>
  <v-app>
    <notification-sidebar v-if="keycloakService.isAuthenticated"/>
    <top-menu v-if="!isInMobileApp" class="shiny"/>
    <v-main>
      <mobile-banner
        class="pt-8 pb-12"
          id="appStore"
          :is-in-mobile-app="isInMobileApp"
      />
      <v-container fluid
                   :class="{'pt-0': !isInMobileApp}"
                   class="px-0 px-sm-3 px-lg-16"
                   :id="'main-view-'+$route.name"
      >

        <incomplete-informations
            v-if="$route.name === 'home'"
            class="mt-8 mt-lg-4"
        />

        <router-view :key="$route.path"
                     class="pa-md-3" />
      </v-container>
    </v-main>
    <erhgo-footer v-if="!isInMobileApp"/>
    <dialog-complete-your-location
        v-if="showLocationPopin && !isInMobileApp"
        @closeLocationPopin="closeLocationPopin"
    />
    <dialog-import-experiences
      v-if="keycloakService.isAuthenticated && !showLocationPopin && $route.name === 'home' && !isInMobileApp"/>
  </v-app>
</template>

<script>
import TopMenu from './views/Topbar/TopMenu';
import ErhgoFooter from '@/views/Footer/Footer';
import IncompleteInformations from './views/User/IncompleteInformations';
import DialogCompleteYourLocation from '@/views/User/DialogCompleteYourLocation';
import NotificationSidebar from '@/components/notifications/NotificationSidebar';
import keycloakService from 'odas-plugins/KeycloakService';
import MobileBanner from '@/MobileBanner.vue';
import { storeThenGetContextFromMobileApp } from '@/mobile-utils';
import DialogImportExperiences from '@/views/User/DialogImportExperiences.vue';

export default {
  name: 'AppLegacy',
  components: {
    MobileBanner,
    NotificationSidebar,
    IncompleteInformations,
    DialogImportExperiences,
    DialogCompleteYourLocation,
    TopMenu,
    ErhgoFooter,
  },
  computed: {},
  data() {
    return {
      keycloakService,
      isInMobileApp: false,
      showLocationPopin: false,
    };
  },
  created() {
    this.isInMobileApp = storeThenGetContextFromMobileApp(this.$route);
    this.showLocationPopin = !!this.$route.query?.showLocationPopin;
    if (!this.isInMobileApp) {
      this.enableFacebookTracker();
    }
  },
  methods: {
    async closeLocationPopin() {
      this.showLocationPopin = false;
      const query = {...this.$route.query};
      delete query.showLocationPopin;
      await this.$router.replace({query});
    },
    enableFacebookTracker() {
      window.fbq('init', '5302692999836413');
      window.fbq('track', 'PageView');
    },
  },
};

</script>
<style>

.v-btn--outlined {
  background-color: white;
}

.v-alert--outlined {
  background-color: white !important;
}

.user-form {
  position: absolute;
  width: 100%;
}

header:not(.v-app-bar--is-scrolled) + .user-form {
  z-index: 7;
  transition-property: z-index;
}

footer {
  z-index: 8 !important;
}

/*
--------------------------------------
stepper header
--------------------------------------
*/

.stepper-registration .v-stepper__step__step {
  padding: 1em !important;
  font-size: 0.75em !important;
  transform: scale(1);
  transition: all 0.2s ease-in-out;
}

.stepper-registration .v-stepper__step--complete > .v-stepper__step__step {
  font-size: 1em !important;
  margin-top: -0.2em;
}

.stepper-registration .v-stepper__step--complete > .v-stepper__step__step {
  background-color: #00f0c8 !important;
}

.stepper-registration .v-stepper__step--active > .v-stepper__step__step {
  background-color: #000000 !important;
  transform: scale(1.5);
}

.stepper-registration .v-stepper__step__step > .v-icon {
  color: #000000 !important;
}

.stepper-registration .v-stepper__step--active > .v-stepper__step__step > .v-icon {
  color: #00f0c8 !important;
}

.stepper-registration .v-stepper__header .v-divider {
  background-color: rgba(0, 0, 0, 0.25);
  height: 1px;
  border: 2px solid transparent !important;
  border-radius: 5px;
}

.stepper-registration .v-stepper__step--complete:not(.v-stepper__step--active) + .v-divider {
  background-color: #00ad91 !important;
}

@media (min-width: 712px) {

  .stepper-registration .v-stepper__header {
    margin-left: -2em;
    margin-right: -2em;
  }

  .stepper-registration .v-stepper__step {
    width: calc(100% / 6);
    padding-left: 0;
    padding-right: 0;
  }

  .stepper-registration .v-stepper__label {
    max-width: 80%;
    text-align: center !important;
    align-items: center;
    display: block !important;
  }

  .stepper-registration .v-stepper .v-stepper__label > small {
    color: #000000 !important;
  }

}

@media (max-width: 711px) {

  .stepper-registration .v-stepper__step {
    flex-basis: initial !important;
    width: 0;
  }

  .stepper-registration .v-stepper__header .v-divider {
    max-width: 5%;
  }

}

/*
--------------------------------------
page
--------------------------------------
*/

.stepper-registration .v-stepper {
  margin-bottom: 0 !important;
  border-radius: 0 !important;
}

.registration-step.v-stepper__items {
  padding: 0 !important;
}

.registration-step .v-stepper__wrapper {
  overflow: visible !important;
}


.registration-step h2 {
  font-size: 1.75em !important;
  line-height: 1.25em;
  text-align: center;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

@media (max-width: 499px) {
  .registration-step h2 {
    font-size: 1.5em !important;
  }
}

.skip-steps {
  margin-top: 0 !important;
  padding: 0 2em 2em;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0 0 0.25em 0.25em;
}

</style>
