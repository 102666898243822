<template>
  <div class="notification">
    <div class="notification-header pa-1">
      <div>
        <v-icon
          small
          class="interaction--text mx-2"
          v-if="notification.state === UserNotificationState.NEW">
          fa-circle
        </v-icon>
        <v-icon
          x-small
          class="shiny--text mx-2"
          v-else>
          fa-check-circle fa-light
        </v-icon>
        <strong class="shiny pa-1">{{ notificationViewObject.title }}</strong>
      </div>
      <div>
        <v-btn small icon outlined color="error" class="ml-5" :loading="loading">
          <v-icon
            x-small
            @click.stop="deleteNotification"
            class="error--text mx-2">
            fa-trash-can fa-light
          </v-icon>
        </v-btn>
      </div>
    </div>
    <v-card
      class="my-0"
      flat
      :class="notification.state === UserNotificationState.NEW ? 'unread grey lighten-2' : 'read white'"
    >
      <component :is="notificationViewObject.component"
                 :notification="notification"/>
    </v-card>
  </div>
</template>
<script>
import { UserNotificationEntityType, UserNotificationState } from 'erhgo-api-client';
import RecruitmentNotification from '@/components/notifications/RecruitmentNotification.vue';
import DefaultNotification from '@/components/notifications/DefaultNotification.vue';
import SuspendedRecruitmentNotification from '@/components/notifications/SuspendedRecruitmentNotification.vue';

const viewObjectForNotificationType = {
  'RECRUITMENT': {component: RecruitmentNotification, title: 'PROPOSITION'},
  'SUSPENDED_RECRUITMENT': {component: SuspendedRecruitmentNotification, title: 'INFORMATION'},
  'DEFAULT': {component: DefaultNotification, title: 'INFORMATION'},
};

export default {
  name: 'NotificationItem',
  components: {RecruitmentNotification, SuspendedRecruitmentNotification, DefaultNotification},
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  computed: {
    notificationViewObject() {
      return viewObjectForNotificationType[this.notification.entityType];
    },
  },
  data() {
    return {
      UserNotificationEntityType,
      UserNotificationState,
      loading: false,
    };
  },
  methods: {
    deleteNotification() {
      this.loading = true;
      this.$emit('delete-notification', this.notification);
    },
  },
};
</script>

<style>
.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: end;
  z-index: 20001;
  position: relative;
}

</style>
