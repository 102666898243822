import Vue from 'vue';
import Router from 'vue-router';
import AppLegacy from './AppLegacy';
import AppNG from './AppNG';
import userProfileInitializer from '@/domain/user/UserProfileInitializer';
import { isRedirectingToDownloadAppPageRequired, storeThenGetContextFromMobileApp } from '@/mobile-utils';

Vue.use(Router);

let previousRoute = '';
const routeName = {
  experiences: 'Mes expériences',
  home: 'Accueil',
  edit_classifications: 'Ma recherche',
  job_candidatures: 'Mes candidatures',
  matching_recruitments: 'Mes offres',
};
const router = new Router({
  mode: 'history',
  hashbag: true,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    } else {
      return {x: 0, y: 0};
    }
  },
  routes: [{
    path: '/inscription',
    component: AppNG,
    children: [
      {
        path: '/inscription',
        name: 'jnspucv',
        component: () => import('./views/ng/registration/RegistrationStepper.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/spontaneous-candidature/:organizationCode',
        name: 'spontaneousCandidature',
        component: () => import('./views/ng/registration/SpontaneousCandidatureStepper.vue'),
        meta: { requiresAuth: true },
        props: true,

      },
      {
        path: '/inscription/confirm-email',
        name: 'confirm_email',
        component: () => import('./views/ng/registration/ConfirmEmail.vue'),
        meta: {requiresAuth: true},
        props: route => ({
          ...route.params,
        }),
      },
      {
        path: '/jobs/:code',
        name: 'job_view',
        component: () => import('./views/ng/recruitment/Page.vue'),
        props: true,
      },
      {
        path: '/candidature/:recruitmentId',
        name: 'candidature_view',
        component: () => import('./views/ng/candidature/CandidatureStepperPage.vue'),
        meta: {requiresAuth: true},
        props: route => ({
          ...route.params,
          recruitmentId: parseInt(route.params.recruitmentId),
        }),
      },
      {
        path: '/download-app', name: 'download_app', component: () => import('./views/DownloadApp.vue'),
      },
    ],
  }, {
    path: '/',
    component: AppLegacy,
    children: [
      {
        path: '/experience/edit_ng/:experienceId?',
        name: 'edit_experience',
        component: () => import('./views/experience/EditExperiencePage.vue'),
        meta: {requiresAuth: true},
        props: route => ({
          ...route.params,
        }),
      },
      {
        path: '/experience/create',
        redirect: '/experience/edit_ng',
      },
      {
        path: '/',
        alias: '/home',
        name: 'home',
        component: () => import('./views/home/Home.vue'),
        meta: {
          requiresAuth: true,
          roles: [
            'CANDIDATE',
          ],
        },
      },
      {
        path: '/pages/:urlKey',
        name: 'landing_page',
        component: () => import('./views/LandingPages/LandingPage.vue'),
        props: true,
      },

      {
        path: '/jobs',
        redirect: '/pages/jobs',
      },
      {
        path: '/candidatures/job',
        name: 'job_candidatures',
        component: () => import('./views/User/candidature/job/CandidaturesPage.vue'),
        meta: {requiresAuth: true},
      },
      {
        path: '/experiences',
        name: 'experiences',
        component: () => import('./views/User/experience/ExperiencesPage.vue'),
        meta: {requiresAuth: true},
      },
      {
        path: '/my-account',
        name: 'my_account',
        component: () => import('./views/User/MyAccount.vue'),
        meta: {requiresAuth: true},
      },
      {
        path: '/my-preferences',
        name: 'my_preferences',
        component: () => import('./views/User/MyPreferences.vue'),
        meta: {requiresAuth: true},
      },
      // legacy CANDIDATURE's route
      {
        path: '/capacitor/R-:recruitmentId',
        name: 'capacitor',
        redirect: '/candidature/:recruitmentId',
      },
      {
        path: '/extra-professional/questions',
        name: 'extra_professional_questions',
        component: () => import('./views/User/extra-professional/questions/ListPage.vue'),
        meta: {requiresAuth: true},
      },
      {
        path: '/criteria/questions',
        name: 'criteria_questions',
        component: () => import('./views/User/criteria/ListPage.vue'),
        meta: {requiresAuth: true},
      },
      {
        path: '/matching-recruitments',
        name: 'matching_recruitments',
        component: () => import('./views/home/AllMatchingRecruitments.vue'),
        meta: {requiresAuth: true},
      }, {
        path: '/edit-behaviors',
        name: 'edit_behaviors',
        component: () => import('./views/User/behavior/EditBehavior.vue'),
        meta: {requiresAuth: true},
      }, {
        path: '/edit-classifications',
        name: 'edit_classifications',
        component: () => import('./views/User/classification/EditClassification.vue'),
        meta: {requiresAuth: true},
      },
      {
        path: '/soft-skills',
        name: 'soft_skills',
        component: () => import('./views/User/SoftSkills.vue'),
        meta: { requiresAuth: true },
      }, // Not found
      {
        path: '*', name: 'not_found', props: () => ({
          previousRoute,
        }), component: () => import('./views/Errors/404.vue'),
      }],
  },

  ],
});

router.afterEach((to, from) => {
  previousRoute = from?.fullPath;
});

router.beforeEach(async (to, from, next) => {
  const shouldRedirectToDownloadAppPage = isRedirectingToDownloadAppPageRequired(to);
  const isNotInMobileApp = !(storeThenGetContextFromMobileApp(to));
  const mailHasToBeConfirmed = to.name !== 'confirm_email' && userProfileInitializer.requiresEmailConfirmation;

  if (shouldRedirectToDownloadAppPage && !userProfileInitializer.requiresEmailConfirmation) {
    return next({name: 'download_app', query: {previous: to.fullPath}});
  }
  await redirectIfAuthenticationRequired(to, next);

  if (mailHasToBeConfirmed && to.name !== 'download_app') {
    return next({name: 'confirm_email', params: {next: to}});
  }

  if (
    to.path === '/' &&
    !userProfileInitializer.finished &&
    isNotInMobileApp &&
    // FIXME : next /confirm_email line is useless, because to can't be "/confirm_email" as it is "/"
    !mailHasToBeConfirmed
  ) {
    return next('/inscription');
  }

  addCorrectTitleToDocument(to);
  next();
});

async function redirectIfAuthenticationRequired(to, next) {
  if (!to.meta.requiresAuth && !to.query.requiresAuth) {
    next();
    return;
  }

  const keycloak = await router.app.$keycloak;
  if (!keycloak.authenticated) {
    await keycloak.login({loginHint: to.query?.loginHint});
  }
}

function addCorrectTitleToDocument(to) {
  const currentNamedRoute = routeName[to.name];
  document.title = `#jenesuisPASunCV${currentNamedRoute ? ` | ${currentNamedRoute}` : ''}`;
}

Vue.$router = router;

export default router;
