<template>
  <v-app class="app-ng">
    <div class="signin-wrap">
      <main>
        <router-view :key="$route.path"/>
      </main>
      <footer class="footer mb-16">
        <h2 class="footer-title">En savoir plus</h2>
        <ul class="footer-links">
          <li><a href="https://jenesuispasuncv.fr" target="_blank">jenesuispasuncv.fr</a></li>
          <li><a href="https://jenesuispasuncv.fr/cgu" target="_blank">Conditions d'utilisation (CGU)</a>
          </li>
          <li><a href="https://jenesuispasuncv.fr/politique-de-confidentialite-jenesuispasuncv/">Politique de
            confidentialité</a></li>
        </ul>
      </footer>
    </div>
  </v-app>
</template>

<script>

export default {
  name: 'AppNG',
};
</script>

<style lang="scss">
@import '~vuetify/src/styles/main.sass';
@import '~vuetify/src/styles/styles.sass';

.v-application--wrap {
  background-color: #EEEEEE;
}

/*
--------------------------------------
layout
--------------------------------------
*/

.signin-wrap {
  position: absolute;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: white;

}

.signin-wrap main {
  flex-grow: 1;
}

@media (min-width: 712px) {
  .signin-wrap main {
    margin: 2em 0;
  }
}

.signin-wrap .chimney {
  margin: 0 auto;
}

@media (min-width: 712px) {
  .signin-wrap .chimney {
    max-width: 40em;
  }
}

@media (max-width: 711px) {
  .signin-wrap .chimney {
    width: 90%
  }
}

.signin-wrap .chimney > * {
  margin: 2em 0;
}

/*
--------------------------------------
header
--------------------------------------
*/

.signin-wrap h1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2em !important;
  margin: 0;
}

.header-logo {
  width: 100%;
  margin-top: -0.25em;
  margin-bottom: -0.25em;
}

.header-titre {
  position: relative;
  z-index: 1;
  padding: 0.125em 0.5em 0.25em;
  margin-bottom: -0.2em;
  line-height: 1.15em;
}

.header-titre:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("@/../public/img/tache_vert.png");
  background-size: 100% 100%;
  z-index: -1;
}

.header-soustitre {
  position: relative;
  z-index: 2;
  padding: 0 0.5em 0.125em;
  font-size: 0.5em;
  line-height: 1.2em !important;
}

/*
--------------------------------------
footer
--------------------------------------
*/


.signin-wrap .footer {
  padding-left: 1em;
  padding-right: 1em;
  background-color: rgba(255, 255, 255, 0.8);
}

@media (min-width: 712px) {
  .signin-wrap .footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
}

@media (max-width: 711px) {
  .signin-wrap .footer {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

.footer-title {
  font-size: 1em;
  margin: 0.25em 0;
}

.footer-title:after {
  content: ":";
  margin: 0 0.5em;
}

.footer-links {
  justify-content: center;
}

@media (min-width: 712px) {
  .footer-links {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0;
    margin: 0;
  }
}

.footer-links > li {
  margin: 0.25em 0;
}

@media (min-width: 712px) {
  .footer-links > li:not(:last-child):after {
    content: "–";
    margin: 0 0.5em;
  }
}


/*
--------------------------------------
basiques
--------------------------------------
*/

.app-ng p {
  line-height: 1.25em;
}

.app-ng strong {
  font-weight: 700;
  color: #007a66;
}

.app-ng a {
  color: #00473d !important;
  display: inline-block;
  padding: 0 0.1em;
  transform: scale(1);
  transition: all 0.2s ease-in-out;
}

.app-ng a:hover,
.app-ng a:focus {
  background-color: #00f0c8;
  transform: scale(1.05);
}

.app-ng [class*="button"] {
  text-align: center;
  border-radius: 0.25rem;
  transform: scale(1);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.button_rb {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.app-ng [class*="button"]:hover,
.app-ng [class*="button"]:focus {
  transform: scale(1.05);
}

.button_primary {
  color: #000000;
  font-weight: 700;
  background-color: #00f0c8 !important;
}

.button_primary:hover,
.button_primary:focus {
  background-color: #2bd6b9 !important;
}

.app-ng .button_secundary {
  color: #007a66;
  background: transparent !important;
  border: #009980 solid 1px !important;
}

.button_secundary:hover,
.button_secundary:focus {
  color: #000000;
  background-color: #00f0c8 !important;
  border: #00f0c8 solid 1px !important;
}

.registration-step {
  padding: 2em 2em 0;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0.25em 0.25em 0 0;
}

.button_basic {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0.75em;
}

.button_light {
  background-color: transparent !important;
  display: flex;
  color: #00473d !important;
}

.button_light_left {
  text-align: left;
}

.button_light_left > .v-icon {
  margin-right: 0.5em;
}

.button_light_right {
  flex-direction: row-reverse;
  text-align: right;
  font-size: small;
  font-style: italic;
}

.button_light_right > .v-icon {
  margin-left: 0.5em;
  margin-bottom: 0.25em;
}

.button_tile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 700;
  height: initial !important;
}

.button_tile > .v-icon {
  margin: 0.5em;
}

@media (min-width: 500px) {
  .tiles {
    margin-left: -0.5em;
    margin-right: -0.5em;
  }

  .button_tile {
    max-width: 10em;
    margin: 0.5em;
    min-width: 10em;
    min-height: 10em;
    padding: 1em !important;
  }

  .button_tile > .v-icon {
    font-size: 3em;
  }
}

@media (max-width: 499px) {
  .tiles {
    margin-left: -0.25em;
    margin-right: -0.25em;
  }

  .button_tile {
    max-width: calc(50% - 0.5em);
    margin: 0.25em;
    padding: 1em 0.5em !important;
  }

  .button_tile > .v-icon {
    font-size: 2.5em;
  }
}

.tag {
  display: inline-block;
  background-color: #fff;
  color: #007a66;
  padding: 0.25em 0.5em;
  border-radius: 1em;
  line-height: 1.1em;
}

.app-ng .v-icon.fal {
  color: #000000;
}

.interaction--content {
  @extend .text-body-1, .pa-3;
  color: var(--v-interaction-base);
  background-color: #EDE7F6;
}


/*
--------------------------------------
stepper header
--------------------------------------
*/

.stepper-registration .v-stepper__step__step {
  padding: 1em !important;
  font-size: 0.75em !important;
  transform: scale(1);
  transition: all 0.2s ease-in-out;
}

.stepper-registration .v-stepper__step--complete > .v-stepper__step__step {
  font-size: 1em !important;
  margin-top: -0.2em;
}

.stepper-registration .v-stepper__step--complete > .v-stepper__step__step {
  background-color: #00f0c8 !important;
}

.stepper-registration .v-stepper__step--active > .v-stepper__step__step {
  background-color: #000000 !important;
  transform: scale(1.5);
}

.stepper-registration .v-stepper__step__step > .v-icon {
  color: #000000 !important;
}

.stepper-registration .v-stepper__step--active > .v-stepper__step__step > .v-icon {
  color: #00f0c8 !important;
}

.stepper-registration .v-stepper__header .v-divider {
  background-color: rgba(0, 0, 0, 0.25);
  height: 1px;
  border: 2px solid transparent !important;
  border-radius: 5px;
}

.stepper-registration .v-stepper__step--complete:not(.v-stepper__step--active) + .v-divider {
  background-color: #00ad91 !important;
}

@media (min-width: 712px) {

  .stepper-registration .v-stepper__header {
    margin-left: -2em;
    margin-right: -2em;
  }

  .stepper-registration .v-stepper__step {
    width: calc(100% / 6);
    padding-left: 0;
    padding-right: 0;
  }

  .stepper-registration .v-stepper__label {
    max-width: 80%;
    text-align: center !important;
    align-items: center;
    display: block !important;
  }

  .stepper-registration .v-stepper .v-stepper__label > small {
    color: #000000 !important;
  }

}

@media (max-width: 711px) {

  .stepper-registration .v-stepper__step {
    flex-basis: initial !important;
    width: 0;
  }

  .stepper-registration .v-stepper__header .v-divider {
    max-width: 5%;
  }

}

/*
--------------------------------------
page
--------------------------------------
*/

.stepper-registration .v-stepper {
  margin-bottom: 0 !important;
  border-radius: 0 !important;
}

.registration-step.v-stepper__items {
  padding: 0 !important;
}

.registration-step .v-stepper__wrapper {
  overflow: visible !important;
}


.registration-step h2 {
  font-size: 1.75em !important;
  line-height: 1.25em;
  text-align: center;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

@media (max-width: 499px) {
  .registration-step h2 {
    font-size: 1.5em !important;
  }
}

</style>
