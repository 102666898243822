<template>
  <v-app-bar color="shiny"
             shrink-on-scroll
             class="align-self-center header"
             app
             :height="80"
             :extension-height="30"
             short>
    <v-img max-width="200" class="mt-2" src="/img/logo_noir.png"/>
    <v-toolbar
      elevation="0"
      dense
      color="primary"
      id="authenticated-menu"
      class="shiny d-none d-lg-flex ml-1 mt-2"
      v-if="authenticated">
      <v-btn small
             text
             v-for="target in menu"
             :key="target.name"
             :to="{ name: target.name}"
             :exact="target.exact"
             class="mx-1 uppercase">
        {{ target.label }}
      </v-btn>
      <v-btn small
             text
             class="mx-1 uppercase"
             :href="faqUri"
             target="_blank">
        Des questions&nbsp;?
      </v-btn>
    </v-toolbar>
    <v-col class="hidden-lg-and-up py-0">
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-app-bar-nav-icon v-on="on" class="hidden-lg-and-up shiny"/>
        </template>
        <v-list dense color="shiny">
          <v-list-item
            v-for="target in menu"
            :key="target.name"
            :exact="target.exact"
            :to="{ name: target.name}">
            <v-list-item-content>
              <v-list-item-title class="uppercase">{{ target.label }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :href="faqUri" target="_blank">
            <v-list-item-content>
              <v-list-item-title class="uppercase">
                Des questions&nbsp;?
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-spacer/>
    <notification-bell class="mt-2 mr-2" v-if="authenticated" />
    <v-menu auto
            offset-y
            transition="slide-y-transition"
            :close-on-content-click="true"
    >
      <template v-slot:activator="{ on }">
        <v-btn v-on="on"
               text
               :left="!isSmallDevice"
               :icon="isSmallDevice"
               class="mt-2"
               id="account-btn"
               small>
          <v-icon>account_circle</v-icon>
        </v-btn>
      </template>
      <v-list dense color="shiny" v-if="authenticated">
        <v-list-item id="userAccount" :to="{name : 'my_account'}">
            <v-list-item-action>
              <v-icon>mdi-face-profile</v-icon>
            </v-list-item-action>
            <v-list-item-title>
              Mon compte
            </v-list-item-title>
          </v-list-item>
          <v-divider/>
          <v-list-item id="userPreferences" :to="{name : 'my_preferences'}">
            <v-list-item-action>
              <v-icon>mdi-settings</v-icon>
            </v-list-item-action>
            <v-list-item-title>
              Mes Préférences
            </v-list-item-title>
          </v-list-item>
          <v-divider/>
          <v-list-item @click="logout">
            <v-list-item-action>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-action>
            <v-list-item-title>
              Me déconnecter
            </v-list-item-title>
          </v-list-item>
        </v-list>

        <v-list v-else>
          <v-list-item @click="login">
            <v-list-item-action>
              <v-icon>mdi-login</v-icon>
            </v-list-item-action>
            <v-list-item-title>
              Se connecter
            </v-list-item-title>
          </v-list-item>
        </v-list>
    </v-menu>
    <template v-slot:extension v-if="authenticated && userProfile.isInitialized">
      <div style="max-width: 100%; min-width: 100%" class="not-scrolled pl-0 pb-0">
        <v-row no-gutters class="pl-5 pl-sm-7 pl-md-11 pb-2">
          <v-col class="text-truncate text-h4 text-md-h3">
            <span class="brush">{{ firstName }} {{ lastName }}</span>
          </v-col>
          <v-col class="justify-end d-flex align-center" cols="3" sm="2" md="1">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                 <download-profile-button :user-profile="userProfile"/>
                </div>
              </template>
              {{
                userProfile.isExportable ? 'Télécharger mon profil #jenesuisPASunCV' : 'Vous pourrez télécharger votre profil quand nous en saurons davantage sur vous !'
              }}
            </v-tooltip>
          </v-col>
        </v-row>
      </div>

      <div style="max-width: 100%" class="pl-5 pl-sm-7 pl-md-11 scrolled text-h4 text-md-h3">
        <span class="brush">{{ firstName }}</span>
      </div>
    </template>
  </v-app-bar>
</template>

<script>
import keycloakService from 'odas-plugins/KeycloakService';
import userProfileInitializer from '@/domain/user/UserProfileInitializer';
import userProfile from '@/views/home/UserProfileProvider';
import NotificationBell from '@/components/notifications/NotificationBell';
import DownloadProfileButton from '@/views/User/DownloadProfileButton.vue';

export default {
  name: 'TopMenu',
  components: {DownloadProfileButton, NotificationBell},
  data() {
    return {
      userProfileInitializer,
      keycloakService,
      authenticated: false,
      authData: null,
      faqUri: 'https://www.jenesuispasuncv.fr/foire-aux-questions/',
      userProfile,
    };
  },
  async created() {
    this.authenticated = keycloakService.isAuthenticated;
    if (this.authenticated) {
      this.authData = keycloakService.authData;
    }
  },
  computed: {
    firstName() {
      return userProfile.firstName || (!this.lastName ? keycloakService.emailPrefix : '');
    },
    lastName() {
      return userProfile.lastName;
    },
    isSmallDevice() {
      return (
        this.$vuetify.breakpoint.name === 'xs'
      );
    },
    rawMenu() {
      return [
        {name: 'home', label: 'Accueil', exact: true, static: true},
        {name: 'edit_classifications', label: 'Ma recherche', static: true},
        {name: 'job_candidatures', label: 'Mes candidatures'},
        {name: 'experiences', label: 'Mes expériences', static: true},
        {name: 'matching_recruitments', label: 'Mes offres'},
      ];
    },
    menu() {
      return this.rawMenu.filter(m => m.static || (userProfile.isInitialized && userProfile.includesMenus.includes(m.name)));
    },
  },
  methods: {
    logout() {
      keycloakService.logout();
    },
    login() {
      keycloakService.login();
    },
  },
};
</script>
<style>
.v-toolbar__extension {
  background-color: var(--v-shiny-base);
  align-items: flex-end;
}

.v-toolbar__content {
  height: 100px !important;
}

.header {
  height: 100px !important;
}


.v-app-bar--is-scrolled .not-scrolled {
  display: none;
}

.v-app-bar:not(.v-app-bar--is-scrolled) .scrolled {
  display: none;
}

.v-app-bar:not(.v-app-bar--is-scrolled) .not-scrolled {
  display: block;
}

.brush {
  font-family: GoodBrush, sans-serif !important;
}

</style>
